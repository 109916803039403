/* SCADA Connection page styling      */


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.SCADA_page{
    
    
    font-family:Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
    backdrop-filter: blur(30px);
}

.wrapper{
    width: 90%; /* Adjust width for responsiveness */
    max-width: 420px; /* Maximum width for larger screens */
    background: transparent;
    border: 1px solid var(--white);
    backdrop-filter: blur(30px);
    color: white;
    border-radius: 10px;
    padding: 30px 40px;
    margin: 20px; /* Add margin for better spacing */
    
    
}
.wrapper h3{
    font-size: 36px;
    text-align: center;
}

.wrapper .input-box{
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input{
    width: 100%;
    height: 100%;
    background:transparent;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
    font-size: 16px;
    color: white;
    padding:  20px 45px 20px 20px;
}

.input-box input::placeholder{
    color: white;
}

.input-box .SCADA_icon{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.wrapper button{
    width: 100%;
    height: 45px;
    background:white;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
}

.crederror{
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    color: rgb(177, 34, 34);
    text-transform:uppercase;
}
@media screen and (max-width > 1024px ) {
    .wrapper {
        max-width: 90%; /* Adjust width for medium-sized screens */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .wrapper h3 {
        font-size: 28px; /* Adjust heading font size for smaller screens */
    }

    .input-box input {
        font-size: 14px; /* Adjust input font size for smaller screens */
    }
}
@media screen and (max-width: 1024px ) {
    .wrapper {
        margin-left: 10%;
        max-width: 60%; /* Adjust width for medium-sized screens */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .wrapper h3 {
        font-size: 28px; /* Adjust heading font size for smaller screens */
    }

    .input-box input {
        font-size: 14px; /* Adjust input font size for smaller screens */
    }
}


@media screen and (max-width: 768px) {
    .wrapper {
        margin-left: 10%;
        max-width: 60%; /* Adjust width for medium-sized screens */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .wrapper h3 {
        font-size: 28px; /* Adjust heading font size for smaller screens */
    }

    .input-box input {
        font-size: 14px; /* Adjust input font size for smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .wrapper {
        max-width: 90%;
        padding: 15px; /* Adjust padding for smaller screens */
        margin-left: 20%;
    }

    .wrapper h3 {
        font-size: 24px; /* Adjust heading font size for even smaller screens */
    }

    .input-box input {
        font-size: 12px; /* Adjust input font size for even smaller screens */
    }
}


.success {
    color: green;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    text-transform:uppercase;
  }
  
  .error {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    color: rgb(177, 34, 34);
    text-transform:uppercase;
  }