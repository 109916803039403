
body {
  margin: 0;
  padding: 0px; 
  overflow: hidden;
  text-decoration: none;
}


.header{
  background-color: #352F44;
  color: #ffffff;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  
}

.header-link{
  margin-left: 10px;
  word-spacing: 5px;
  color: #ffffff;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  text-decoration: none;
}

/* Logout CSS */

.logout-username{
  display: flex;
  margin-right: 20px;
  gap: 20px;
}
.logged-username{
  font-size: 18px;
  margin: 1px;
}

.logout-icon{
 font-size: 30px;
}
.logout-dropdown {
  position: relative;
  display: inline-block;
  
}
.logout-dropdown-content {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}
.logout-dropdown:hover .logout-dropdown-content {
  display: block;
}
.logout-dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.logout-dropdown-content p:hover {
  background-color: #78708d;
  color: white;
}
/* Logout CSS complete */




.login-container{
  
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.login-centered {
  
  margin-top: 100px;
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 400px;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.1);
}
.login{
  word-spacing: 10px;
  font-size: xx-large;
  font-family: 'Times New Roman', Times, serif;
  color: #092bb3;
  font-weight: bolder;
  
}
@media screen and (max-width : 270px) {
    .login-centered {
    position: relative;
    top: 30%;
    max-width: 100px;
    padding: 30px;
    }
    .login{
      font-size: x-large;
    }
    }
@media screen and (min-width : 270px) and (max-width : 360px) {
    .login-centered {
    position: relative;
    top: 30%;
    max-width: 300px;
    padding: 30px;
    }
    }

@media screen and (min-width : 360px) and (max-width : 420px) {
  .login-centered {
  position: relative;
  top: 30%;
  max-width: 350px;
  padding: 30px;
  }
  }
@media screen and (min-width : 420px) and (max-width : 460px) {
  .login-centered {
  position: relative;
  top: 30%;
  max-width: 400px;
  padding: 30px;
  }
  }
@media screen and (min-width : 460px) and (max-width : 768px) {
  .login-centered {
  position: relative;
  top: 30%;
  max-width: 400px;
  padding: 30px;
  }
  }
@media screen and (min-width : 768px) {
  .login-centered {
  position: relative;
  top: 30%;
  max-width: 400px;
  padding: 30px;
  }
  }


.org-select, .site-select{
  font-family: 'Times New Roman', serif;
  display: flex;
  justify-content: center;
  font-size: xx-large;
  margin-top: 20px;
}







h2{
  margin: 0;
  padding-bottom: 20px;
}
form {

  text-align: left;
}

.form-group {
  margin-bottom: 10px;
}

label{
  display: block;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: medium;
}

.Submit-button {
  
  margin-top: 10px;
  width: 50%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}

.Submit-button-div{
  display: flex;
  justify-content: center;
  align-items: center;
}


.Submit-button:hover {
  background-color: #0056b3;
}

.Forgot_password{
  display: flex;
  margin-top: auto;
  margin-top: 10px;
  justify-content: left;
  text-align: left;
  text-decoration: none;
}





.top_section{
  display: flex;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
}

.logo{
  font-size: 20px;
}

.bars{
  display: flex;
  font-size: 25px;
  margin-left: 0px;
}

.menu-items{
  color :#fff;
  padding: 10px 15px;
  gap:5px;
  transition: all 0.5s;
  position: relative;
  display: inline-block;
  width: auto;
  display: flex;
}

.menu-items:hover{
  background-color:#B9B4C7;
  color: #000;
  transition: all 0.5s;

}


.icon{
  font-size: 25px;
}

.link_text{
  font-size: 20px;
}


.file-upload-container{
  background-color:#5C5470;
  width: auto;
  border-width: 0px;
  border-style: ridge;
  border-color: #352F44 ;
}
.file-upload-card{
  width: auto;
  margin-top:20px;
  margin-left: 0px;
  font-size: 13px;
  border: none;
  font-weight: bold;
  color: #000;
  
}
.file-upload-card-btn{
  font-size: medium;
  margin-top:5px;
  margin-left: 40px;
  margin-bottom: 10px;
  background-color:#352F44;
  color: white;
}
.Upload_msg{
  margin-left: 10px;
  font-size: medium;
}
.upload-btn{
  font-size: medium;
  margin-top:5px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color:#352F44;
  color: white;
}
.Upload_msg_error{
  margin-left: 10px;
  font-size: medium;
  color:red;
}
.dropdown{
font-size: 20px;
}

.refresh-data-btn{
  display: flex;
  position: absolute;
  top: 80px;
  right: 20px;
  height:40px;
  width: 30px;
  color: #287cd6;
  z-index: 999;
  cursor:pointer;
}
.refresh-button{
  background-color: white;
  border: none;
  font-size: 35px;
  cursor:pointer;
  display: flex;
  position: absolute;
  top: 80px;
  right: 20px;
  height:40px;
  width: 50px;
  color: #287cd6;
  
}

.disabled{
  color: #352F44;

}

.grid{
background-color: white;
  border: none;
  font-size: 20px;
  cursor:pointer;
  display: flex;
  position: absolute;
  top: 100px;
  right: 20px;
  height:20px;
  width: 30px;
  color: #287cd6;
  
}

.scada-screen{
  background-color:white ;
  height: 100vh;
  display: flex;
  
}
.AiOutlineClose{
  color:#ffffff;
  font-size: x-large;
  font-weight: bolder;
  position: absolute;
  top: 80px;
  right: 20px;
  cursor: pointer;
  color: #22114d;
}
.AiOutlineClose:hover{
  font-size: 30px;
  font-weight: bolder;
  color: #22114d;
}

.OMcontainer{
  background-color:  white;
  height: 250px;
  width: 600px;
  position: absolute;
  top: 120px;
  margin-left: 60px;
}


.OMlabel {
  color: #22114d;
  font-size: 25px;
  height: 30px;
  width: 200px;
  margin-top: 10px;
  margin-left : 220px;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center;
  font-family: 'Times New Roman', Times, serif;
}


.OMFileContainer, .Scada-Cred-Container{
  background-color: #352F44;
  position: absolute;
  top: 10vh;
  margin-left: 100px;
  width: 450px;
  height: 425px;
  color: #B9B4C7;
}
.Scada-Cred-Container{
  background-color: #352F44;
  position: absolute;
  top: 10vh;
  margin-left: 100px;
  width: 450px;
  height: 425px;
  color: #B9B4C7;
}

.label
{
  font-size: 25px;
  margin-left: 70px;
  margin-top: 10px;
}


.grid-icon{ 
  background-color: white;
  border: none;
  font-size: 20px;
  cursor:pointer;
  display: flex;
  position: absolute;
  top: 200px;
  right: 20px;
  height:20px;
  width: 30px;
  color: #287cd6;
}


/*...........................*/



/*   Completed    */


/*        chat page       */

.chat_page {
  height:100%;
  width: auto;
  margin: 5px;
  border-radius: 1%;
}
* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}



/* Style the tab and button */
.tab {
  float: left;
  background-color: none;
  width: 7%;
  margin-left:2%;
  height: auto;
  margin-top:2%;
  
}

.tab button {
  display: block;
  border-radius: 1px;
  background-color: #4d4272;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 16px;
  padding: 10px 8px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  border-top-left-radius: 20px;
  margin-top: 8px;
}
.tab button span{
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}
.tab button span:after{
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}

.tab button:hover span {
  padding-right: 25px;
}

.tab button:hover span:after {
  opacity: 1;
  right: 0;
}


.tab button:hover {
  background-color: #5e577f;
}
.tab button.active {
  background-color: #ccc;
}


/* Style the tab content */
.tabcontent {
  float: left;
  padding: 5px 5px;
  border: 0.5px solid #ccc;
  border-radius: 10px;
  width: 90%;
  height: 100%;
}





/*.......popup scada connection styling.......*/


/*    completed  */


/*    files page  */


/*.... organization site list page .....*/
.org_list_header,.site_list_header{
  color: black;
  text-align: center;
  font-family:'Times New Roman', Times, serif;

}
.org_list{

  background-color: #ffffff;
  color: #352F44; 
  width: inherit;
  height: max-content;
  padding: 10px;
  margin-top: 20px;
}
.org_list:hover{
  background-color: rgb(139, 186, 212);
  color: white;
  display: flex;
  justify-content: space-between;

}


.org_custom_list {
  list-style-type: none; 
  padding: 0;
  
}

.org_custom_list li {
  margin-left: 5px; 
  background: url('/src/assets/icons8-company-100.png') no-repeat;
  background-size: contain;
  padding-left: 50px; 
  line-height: 1.5; 
}
.site_custom_list {
  list-style-type: none; 
  padding: 0;
  
}

.site_custom_list li {
  margin-left: 1px; 
  background: url('/src/assets/icons8-solar-energy-96.png') no-repeat;
  background-size: contain;
  padding-left: 70px; 
  line-height: 1.5; 
}


.arrow-icon {
  background-size: contain; 
  display: none;
  width: 40px; 
  height: 20px; 
  vertical-align: center;
  margin: 5px; 
}


.org_list:hover .arrow-icon {
  display: inline-block;
}
.site_select{
  
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  background-color: #ffffff;
  color: #352F44; 
  border: 2px solid #352F44;
  
}
.site_select:hover {
  background-color: #352F44;
  color: white;
}


