.Chat_section{
    background-color: var(--col2);
    height: 100vh;
    padding: 0px 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    
}


.bottomsection{
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: 80%
}

.bottomsection svg{
    width: 25px;
    height: 25px;
    background-color: gray;
    padding: 5px;
    border-radius: 5px;

}


.bottomsection .messagebar{
    border: 1px solid gray;
    align-items: center;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    justify-content: space-between;
    width: 80%;
}

.bottomsection .messagebar input{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    color: var(--textcol1);
    width: 100%;
}

.bottomsection svg:hover{
    cursor: pointer;
    background-color: white;
}


.chat-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--textcol1);
    font-size: 16px;
    height: 80%;
    position: relative;
    width: 60%;   
}


.message {
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    clear: both;
    display: block;
  }
  
  .message.user {
    background-color: #7e7f8a;
    color: #e1e4e1;
    text-align: right;
    float: right;
    max-width: 70%;
  }
  
  .message.assistant {
    background-color: #596a5a;
    color: white;
    text-align: left;
    float: left;
    max-width: auto;

  }
  


/* FloatingChatIcon.css */
.floating-chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    
  }
  
  .floating-chat-icon img {
    width: 40px;
    height: 40px;
    border-radius: 50%; 
  }
  
  .chat-dialog-box {
    position: absolute;
    bottom: 70px;
    right: 0;
    width: 200px;
    padding: 10px;
    background: gray;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: white;
  }
  
  .chat-dialog-box h4 {
    margin: 0 0 10px 0;
  }
  
  .chat-dialog-box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .chat-dialog-box li {
    margin-bottom: 5px;
  }
  


  .msgbox {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #888 #e0f7fa; /* Firefox */
  }
  
  .msgbox::-webkit-scrollbar {
    width: 5px; /* Adjust scrollbar width */
  }
  
  .msgbox::-webkit-scrollbar-track {
    background: #e0f7fa; /* Track color */
  }
  
  .msgbox::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #e0f7fa; /* Padding around handle */
  }

  .chat-messages {
    
    padding-right: 10px; 
    max-height:auto;
    overflow-y: auto;
    
  }

/* Global styles for all elements */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

/* Target common HTML elements */
p {
  margin-bottom: 15px;
}

h1, h2, h3 {
  color: #4CAF50;
}

ul, ol {
  margin-left: 20px;
  padding-left: 20px;
}

li {
  margin-bottom: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

a {
  color: #1e90ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
