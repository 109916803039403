
:root{
    /*colors*/
    --darkGreen : #5c8d89;
    --green: #4B5A20;
    --lightGreen : #a7d7c5;
    --verylightGreen : #f4f9f4;
    --darkGreey : #828282;
    --white : #ffffff;
    --lightGrey : #C2C2C2;
    --black : #2A2F33;
    --lightblack : rgb(64, 67, 70);
    --nomad : #BBB6A5;
    --Manatee : #8C8C9C;
    --Mantle : #8C9491;
    --Mischka : #DADADE;
    --Tan : #B68D40;
    --col1: rgb(13,13,13);
    --col2: #181414;
    --textcol1: white;
   



    /*font family*/
    --fontFamily : "Andada Pro", serif;
    --lightFont : 600;
    --heavyFont : 800;

    /*fonts*/
    --logoFont : calc(1.25 * 31.42);
}

