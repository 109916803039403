@import "./__variables.css";
body{
    height: 100vh;
    width: 100vw;
    font-family: var(--fontFamily);
    background-color: var(--verylightGreen);
    
  }
*{
    margin: 0;
    padding: 0;
}
/*h1{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}*/

.sidebar{
    background-color: var(--black);
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem 1rem;
    width: 15rem;
    height: 100vh;
    z-index: 1;
    flex-shrink: 0; /* Prevent the sidebar from shrinking */
    margin-right: 20px;
    
}

.logoContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}
.logo{
    width: auto;
    height: 2.5rem;
    border-radius: 50%;
    
}

h2.title{
    font-size: 25.42px;
    color: var(--Mischka);
    padding: 0 0 0 1rem;
    
    
}

.burgerContainer{
  
    content: "";
    background-color:var(--Mantle) ;
    position: absolute;
    right: calc(-35px/2); 
    top: calc(5rem / 1.15);
    width: 35px;
    height: 35px;
    border-radius: 2rem;
    z-index: 1;
    
}
.burgerContainer::after{
    content: "";
    position: absolute;
    right: 35px;
    top: calc(35px/2);
    z-index: 0;
    width: calc(6rem + 15rem);
    background-color:var(--white);
    height: 2px;
    
}

.burgerTrigger{
    content: "";
    width: 35px;
    height: 35px;
    background-color: red;
    cursor: pointer;
    z-index: 6;
    opacity: 0;
    position: relative;
}
.burgerMenu{
    content: '';
    background-color: var(--Mantle);
    position: relative;
    z-index: 1;
    width: 20px;
    height: 3px;
    border-radius: 2rem;
    bottom: calc(35px / 2);
    left: 0.45rem;
    
}
.burgerMenu::after{
    content: '';
    background-color: var(--white);
    position: absolute;
    width: 20px;
    height: 3px;
    border-radius: 2rem;  
    top: 0.4rem;
    transform: translateY(-0.4rem) rotate(45deg);
    
}
.burgerMenu::before{
    content: '';
    background-color: var(--white);
    position: absolute;
    width: 20px;
    height: 3px;
    border-radius: 2rem;
    top: -0.4rem;
    transform: translateY(0.4rem) rotate(-45deg);
    
}
    

.profileContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0;
    bottom: 1rem;
    position: absolute;
    width: inherit;
    
}
.profile{
    width: auto;
    height: 1.5rem;
    border-radius: 50%;
}

.profileContents{
    display: flex;
    align-items:flex-start;
    padding: 0 0 0 1rem;        
}
.name{
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: var(--Mischka);
    text-transform: capitalize;
}           

p{
    padding: 0;
    margin: 0;
    color: var(--Manatee);
    text-transform: lowercase;
}   
    
.contentContainer{
    padding: 2rem 0 0 0;
    margin: 2rem 0 0 0;
   
}
.ulist{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 5%;
}
.lilist{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0.1rem 0;
    padding: 0.3rem 0;
    width: 100%;
    border-radius: 1rem;
    cursor: pointer;
}

.icon_style{
    width: auto;
    height: 1.5rem;
}
.link{
    text-decoration: none;
    color: var(--Mischka);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 0.5rem;
}

.sidebar.active{
    width: 3rem;
    transition: 0.5s all;
    
}

.sidebar.active .logoContainer.active h2.title{
    opacity: 0;
}
.logoContainer.active{
    transform: translateX(-0.7rem);
    width: auto;
    height: 2.5rem;
    border-radius: 50%;
    transition: 0.5s all;
}
.burgerMenu.active{
    background-color: var(--white);
}

.burgerMenu.active::before{
    transform: translateY(0rem) rotate(0deg);
}

.burgerMenu.active::after{
    transform: translateY(0rem) rotate(0deg);
}

.sidebar.active .logoContainer.active .profileContainer .profileContents{
    opacity: 0;
}

.lilist:hover{
    
    transition: 0.25s all;
}
.link:hover{
    color: var(--Manatee);
}
.link.active{
    pointer-events: none;
    cursor: not-allowed; 
    opacity: 0;
}
.profileContents.active{
    opacity: 0;
}

.lilist.active{
    margin: 0.05rem 0;
    padding: 0.05rem 0;
    transition:5ms all;
    transform: translateX(-0.05rem) translateY(0deg);
    cursor:inherit;
}

.profileContainer:hover{
    cursor: pointer;
}

.dropUpCard {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: absolute;
    bottom: calc(100% + 10px); /* Position the drop-up card above the profile container */
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }
.profileContainer .dropUpCard {
    visibility: visible;
    opacity: 1;
  }



/* Chat page code */ 
.main_box{
  display: flex;
  flex-direction: row;
  
  width: 100vw;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #333;
}
.leftside{
  background-color: var(--col1);
  width: 15%;
  overflow: hidden;
}
.rightside{
    background-color: var(--col2);
  width: 85%;
  overflow: hidden;
}