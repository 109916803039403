.leftSection{
    background-color: var(--col1);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.newChat{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    height: 10%;
}

.text1{
    color: var(--Mischka);
    font-size: 15px;
}
.title{
    color: var(--textcol1);
    font-size: 18px;
    text-transform: capitalize;
}
.newChat div{
    display: flex ;
    align-items: center;
    gap: 10px;
}

.newChat svg{
    width: 30px;
    height: 30px;
    color: var(--textcol1);
}

.allChats{
    height: 70%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 20px;
}


.newChatIcon {
    cursor: pointer;
    height: 20px;
    
}

.chat-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon-container {
    margin-right: 10px; /* Adjust margin as needed */
    color: var(--Mischka);
  }
  
  .text-container {
    flex-grow: 1;
  }
  
  .summary_button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    text-align: left;
    color: var(--Mischka);
  }
  
  .summary_button:hover {
    text-decoration: underline;
  }

