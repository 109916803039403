@import "./__variables.css";
/* CSS Styles */
.file-list-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: var(--col2);
    width: 100%;
    min-height: 100vh; 
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: scroll;
  }
  .file-list-container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    
  }
  .file-list-container::-webkit-scrollbar-track {
    background: var(--col2);
    
  }
  .file-list-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 6px; 
  }
  
 
  .file-list-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  

  .Files_heading {
    font-size: 24px;
    font-weight: bold;
    text-align: right; 
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    justify-content: right; 
    align-items: right; 
    color: var(--Mischka);
  }
  
  .divider {
    border-bottom: 1px solid #c7b9b7;
    margin-bottom: 15px;
  }
  
  .Upload_file_button {
    font-size: large;
    color: var(--white);
    background: var(--Mantle) ;
    padding: 10px 15px;
    margin-top: 15px;
    outline: #b6a4a1;
    border: solid   ;
    border-radius: 50%;
    
  }
  .header-container {
    display: flex;
    justify-content: right; 
    align-items: right;
  }
  .Upload_file_button:hover{
    background-color: #333;
  }



  .ollist {
    list-style-type: none;
    padding: 10px 10px;
    margin: 2% 3% 0% 3%;
    border-radius:10px ;
  }
  
  .filelist {
    display: flex;
    align-items: center;
    padding: 10px;
    
  }
  .filelist:hover {
    background-color: var(--lightblack);
    border-radius:10px ;
  }
  
  .filelisticon {
    margin-right: 10px;
    color: #bb2727;
    width: 30px;

    
  }
  .csvfilelisticon {
    margin-right: 10px;
    width: 40px;

  }
  
  .file-name-container {
    flex-grow: 1;
  }
  
  .file-name {
    font-size: 16px;
    color: white;
  }
  
  .deleteicon {
    cursor: pointer;
    width: 30px;
  }
  .deleteicon:hover {
    filter: brightness(0) invert(1); /* Change color to white */
  }
  
  .Upload_file {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
  }
  
  
  
  .file-upload-card {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .upload-btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #2ecc71; /* Change the color as needed */
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .upload-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  /* Base styles */
/* ... (existing styles remain unchanged) ... */

/* Media query for smaller devices */
  


  

  .container {
    position: relative;
  }
  
  .card {
    position: relative;
    top: 50%;
    left: 50%;
    width: 30%;
    height: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; 
    
  }
  
  .file-upload-card {
    /* Add your styles for the file upload input here */
    margin-bottom: 10px;
    /* Example styles */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .upload-btn {
    /* Add your styles for the upload button here */
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .upload-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .toggle-btn {
    /* Add your styles for the toggle button here */
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .toggle-btn:hover {
    background-color: #0056b3;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    color: #999;
  }
  

  /* Base styles */

/* ... (existing styles) ... */

/* Media query for smaller devices */
@media screen and (max-width: 600px) {
    /* Adjust styles for smaller screens here */
    .file-list-container {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
      }
    
      .filelist {
        
        flex-direction: row;
        margin-left: 10%;
      }
      
    
      .filelisticon {
        margin-right: 0;
        margin-bottom: 10px;
      }
      
      .deleteicon {
        margin-left: auto;
      }
    .card {
      width: 90%;
      padding: 10px;
    }
  
    .file-upload-card {
      /* Example styles for file upload input on smaller screens */
      width: 100%;
      margin-bottom: 10px;
    }
  
    .upload-btn {
      /* Example styles for upload button on smaller screens */
      width: 100%;
      margin-top: 10px;
    }
  
    .toggle-btn {
      /* Example styles for toggle button on smaller screens */
      width: 100%;
      margin-top: 10px;
    }
  
    .close-btn {
      /* Example styles for close button on smaller screens */
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
  }
  
  /* Additional media queries and adjustments as needed for other screen sizes */

  .success-message {
    color: green;
    margin-top: 10px;
    font-weight: bold;
  }
  .upload-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
  }

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup card */
.popup-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  text-align: center;
}

/* Buttons */
.popup-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #d32f2f;
}

.cancel-btn:hover {
  background-color: #aaa;
}




.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup card */
.popup-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  text-align: center;
}

/* Buttons */
.popup-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-btn:hover {
  background-color: #d32f2f;
}

.cancel-btn:hover {
  background-color: #aaa;
}

.popup-card.success {
  border: 2px solid #4caf50;
  color: #4caf50;
}

.folders {
  display: flex;
  flex-direction: column; /* Stacks the folders vertically */
  gap: 20px; /* Adds proper spacing between folders */
  padding: 10px;
}

.folder {
  display: flex;
  align-items: center; /* Vertically aligns icon and text */
  border-radius: 8px; /* Makes corners rounded */
  padding: 10px 15px; /* Adds spacing inside the folder block */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Adds a hover effect */
}

.folder:hover {
  background-color: #e6e6e6;
  
   /* Changes background color on hover */
}

.folder-icon {
  width: 36px; /* Sets the icon size */
  height: 34px;
  margin-right: 10px; /* Adds space between the icon and text */
  
}

.folder-name {
  font-size: 16px; /* Adjusts font size for better visibility */
  font-weight: 500; /* Makes the font slightly bold */
  color: white; /* Dark text color for readability */
  transition: color 0.3s ease; 
}
.folder:hover .folder-name {
  color: #000; /* Change to black on hover */
}